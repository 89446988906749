/* PageLoader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
}

.loader-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px; /* Optional: add border-radius for a nicer look */
}
